<script>

import * as XLSX from 'xlsx';

export default {
    name: 'ExcelMixin',
    methods: {
        /**
         * Export data to excel file .xlsx
         * @param {Object<[key]: [title]>} header - table header
         * @param {JSON} data - exported data
         */
        exportExcel(header, data, sheetName, fileName) {

            const cleanedData = [];

            data.forEach(item => {
                let obj = {};
                for (const key in header) {
                    if (item[key] !== undefined && item[key] !== null) {
                        obj[key] = item[key];
                    } else {
                        obj[key] = '';
                    }
                }
                cleanedData.push(obj);
            });

            const headerList = [];
            for (const key in header) {
                headerList.push(header[key]);
            }

            /* generate worksheet and workbook */
            const worksheet = XLSX.utils.json_to_sheet(cleanedData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            /* fix headers */
            XLSX.utils.sheet_add_aoa(worksheet, [headerList], { origin: "A1" });

            /* calculate column width */
            if (cleanedData.length > 0) {
                const colWidths = [];
                for (const key in header) {
                    const max_width = cleanedData.reduce((w, r) => Math.max(w, r[key] ? r[key].length : 0), 10);
                    colWidths.push({ wch: max_width });
                }
                if (colWidths.length > 0) {
                    worksheet["!cols"] = colWidths;
                }
            }

            /* create an XLSX file and try to save to [fileName] */
            XLSX.writeFile(workbook, fileName, { compression: true });
        }
    },
}
</script>