<template>
    <v-container fluid>
        <PageHeaderSlot> 
            <template slot="top-right-slot" v-if="userType !== 'tutor'">
                <v-btn text depressed class="primary ml-2" @click="handleUpgradeStudent()" :loading="$store.getters.isLoading">
                    <v-icon size="20" class="mr-3">{{ icon.mdiArrowUpCircleOutline }}</v-icon>
                    學生升級
                </v-btn>
                <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateStudent' }">
                    <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增學生
                </v-btn>
            </template>
        </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            enableSearch
            searchPlaceholder="搜尋學生編號、學生姓名、電話"
            @search-clicked="searchKeyword($event)"
            @search-input="updateSearchKeyword($event)"
            enableClearAll
            @all-search-cleared="clearSearch()"
            @refresh-clicked="refreshData()"
            enableRefresh
            @options-update="onTableOptionsChange($event)"
            enableExport
            @export-clicked="exportData()"
            actionViewRouteLink="StudentDetails"
            enableAdvancedSearch
            @advanced-search-clicked="openAdvancedSearchDialog"
            enableMultiTypeSearch
            :searchTypeOptions="searchTypeOptions"
            :searchType.sync="tableSearchType"
            :initSearchKeyword="querySearch"
        ></Datatable>

        <ConfirmDialog ref="confirmUpgradeDialog" title="是否學生升級？" :isDeleteDialog="false">是否確定要學生升級？此動作無法復原。</ConfirmDialog>
    </v-container>
</template>

<script>
import { mdiArrowUpCircleOutline, mdiPlusCircleOutline } from '@mdi/js';

import Datatable from '@/components/Datatable.vue';
import ExcelMixin from '@/mixins/ExcelMixin.vue';

import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name: 'StudentList',
    mixins: [ExcelMixin],
    components: {
        Datatable,
        ConfirmDialog
    },
    computed: {
        query() {
            return this.$route.query;
        },
        querySearch() {
            return this.$route.query.search || '';
        }
    },
    data: () => ({
        userType: '',
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSearchKey: '',
        tableSearchType: 'id',

        exportLoading: false,

        // --- static data
        icon: {
            mdiArrowUpCircleOutline, 
            mdiPlusCircleOutline
        },
        tableHeaders: [
            { value: 'student_id', text: '學生編號' },
            { value: 'grade', text: '年級' },
            { value: 'student_name', text: '姓名' },
            { value: 'student_school_name', text: '學校' },
            { value: 'phone', text: '電話' },
            { value: 'reg_date', text: '註冊日期' },
            { value: 'actionView', text: '', align: 'end' },
        ],
        searchTypeOptions: [
            { value: 'id', text: '學生編號' },
            { value: 'name', text: '學生姓名' },
            { value: 'phone', text: '電話' },
        ],
    }),

    methods: {
        async getPayload() {
            let payload = {
                filter_limit: this.tableLimit,
                filter_page: this.tablePage - 1,
            };

            const selectedSchool = await this.getUserSelectedSchool();
            if (this.$validate.DataValid(selectedSchool)) {
                payload.center_id = selectedSchool;
            }

            if (this.$validate.DataValid(this.tableSearchKey)) {
                const keyword = this.tableSearchKey.trim();

                if (this.$validate.DataValid(this.tableSearchType)) {
                    if (this.tableSearchType === 'id') {
                        if (this.$validate.isValidId(keyword)) {
                            const id = keyword.substring(1);
                            payload.filter_item = [{ key: 'id', value: parseInt(id) }];
                        } else if (this.$validate.regexNumber(keyword)) {
                            payload.filter_item = [{ key: 'id', value: parseInt(keyword) }];
                        }
                    } else {
                        payload.filter_item = [{ key: this.tableSearchType, value: keyword }];
                    }
                } else {
                    payload.search = keyword;
                }
            }

            return payload;
        },
        async getStudentData(regenUrl = true) {
            if (regenUrl === true) {
                this.regenerateURL();
            }

            this.tableLoading = true;
            try {
                const payload = await this.getPayload();
               
                const { data, total } = await this.$Fetcher.GetStudents(payload);
                this.tableData = data.map(el => {
                    return {
                        id: el.id,
                        student_id: el.id,
                        grade: el.grade,
                        student_name: el.student_name,
                        student_school_name: el.student_school_name,
                        phone: el.phone,
                        reg_date: el.reg_date,
                    }
                });
                
                this.tableItemTotal = total;
            } catch {
                this.tableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        searchKeyword(keyword) {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchKey = keyword.trim();
            this.getStudentData();
        },
        updateSearchKeyword(keyword) {
            this.tableSearchKey = keyword;
        },
        clearSearch() {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchKey = '';
            this.getStudentData();
        },
        refreshData() {
            this.tableLimit = 10;
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.getStudentData();
        },
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getStudentData();
        },

        // ========= 進階搜尋 =========
        openAdvancedSearchDialog() {},

        // ========= 學生升級 =========
        async handleUpgradeStudent() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                })
                return;
            }
            const confirm = await this.$refs.confirmUpgradeDialog.show();

            if (confirm) {
                this.$store.dispatch('setLoading', true);
                try {
                    await this.$Fetcher.UpgradeStudents();
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '升級成功',
                        type: 'success',
                        refresh: true
                    });
                } catch (errMsg) {
                    this.setDialogMessage({
                        title: '升級失敗',
                        message: errMsg,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ========= 匯出報表 =========
        async exportData() {
            if (this.exportLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.exportLoading = true;

            try {
                const header = {
                    student_id: '學生編號',
                    grade: '年級',
                    student_name: '姓名',
                    student_school_name: '學校',
                    parent_phone: '電話',
                    reg_date: '註冊日期'
                }
                const payload = await this.getPayload();
                const { data } = await this.$Fetcher.GetStudents(payload);
                const exportData = data.map(el => {
                    return {
                        student_id: el.id,
                        grade: el.grade ? this.$grade[el.grade] : '',
                        student_name: el.student_name,
                        student_school_name: el.student_school_name,
                        parent_phone: el.phone,
                        reg_date: el.reg_date,
                    };
                });

                this.exportExcel(
                    header,
                    exportData,
                    '學生管理',
                    `學生列表_${this.$formatter.formatDate(new Date()).replace(/-/g, '')}.xlsx`,
                );
            } catch(err) {
                this.$common.error(err);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '匯出失敗',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
            } finally {
                this.exportLoading = false;
            }
        },



        getQuery() {
            if (Object.keys(this.query).length > 0) {
                if (this.$validate.DataValid(this.query.searchType)) {
                    this.tableSearchType = this.query.searchType;
                }

                if (this.$validate.DataValid(this.querySearch)) {
                    this.tableSearchKey = this.querySearch;
                }

                if (this.$validate.DataValid(this.query.page) && this.$validate.regexNumber(this.query.page)) {
                    this.tablePage = parseInt(this.query.page);
                }

                if (this.$validate.DataValid(this.query.limit) && this.$validate.regexNumber(this.query.limit)) {
                    this.tableLimit = parseInt(this.query.limit);
                }
            }
        },

        regenerateURL() {
            const newQuery = {
                searchType: this.$validate.DataValid(this.tableSearchKey) && this.$validate.DataValid(this.tableSearchType) ? this.tableSearchType : undefined,
                search: this.$validate.DataValid(this.tableSearchKey) && this.$validate.DataValid(this.tableSearchType) ? this.tableSearchKey.trim() : undefined,
                page: this.$validate.DataValid(this.tablePage) ? (this.tableLimit === 10 && this.tablePage === 1 ?  undefined : this.tablePage) : undefined,
                limit: this.$validate.DataValid(this.tableLimit) ? (this.tableLimit === 10 && this.tablePage === 1 ?  undefined : this.tableLimit) : undefined,
            }
            Object.keys(newQuery).forEach(key => {
                if (newQuery[key] === undefined) {
                    delete newQuery[key];
                }
            })

            let diff = false;
            if (Object.keys(newQuery).length !== Object.keys(this.query).length) {
                diff = true;
            } else {
                const keys = Object.keys(newQuery);
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const newItem = typeof newQuery[key] === 'string' ? newQuery[key] : newQuery[key].toString().trim();
                    const item = typeof this.query[key] === 'string' ? this.query[key] : this.query[key].toString().trim();
                    if (newItem !== item) {
                        diff = true;
                        break;
                    }
                }
            }

            if (diff === true) {
                this.$router.replace({ query: newQuery });
            }
        },
    },
    async created() {
        const check = await this.checkUserData();
        if (check) {
            this.userType = await this.getUserType();
            this.getQuery();
            await this.getStudentData(false);
        }
    }
}
</script>